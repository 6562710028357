.custom-card-hover{
    background-color: $custom-light;
    position: relative;
    border-radius: 10px;
    &:after{
        position: absolute;
        top: 0px;
        content: '';
        height: 0px;
        background-color: $bg-base;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        z-index: 2;
        transition: all .3s ease;
        opacity: 0;
    }
    &:hover{
        &:after{
            height: 5px;
            opacity: 1;
        }
    }
}

.custom-card-active{
    background-color: $custom-light;
    position: relative;
    border-radius: 10px 10px;

    &:after{
        position: absolute;
        top: 0px;
        content: '';
        height: 10px;
        background-color: $bg-base;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        z-index: 2;
        transition: all .3s ease;
    }
}
