@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggqxSuXd.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggOxSuXd.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggSxSuXd.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggixSuXd.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSuXd.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  font-family: "Manrope";
}

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  background: rgba(255, 0, 79, 0.8);
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 0, 79);
}

@media screen and (max-width: 600px) {
  body::-webkit-scrollbar {
    width: 5px;
  }
  body::-webkit-scrollbar-track {
    border-radius: 5px;
  }
}
/*HEADER*/
.base-hover {
  color: black;
  transition: color 0.3s ease;
}

.left-81 {
  left: 81% !important;
}

.right-81 {
  right: 81% !important;
}

.faq-nav .nav-item .active {
  border: 1px solid rgb(255, 0, 79);
}

.faq-side-bg-1 {
  background: linear-gradient(0deg, #F2DDDD, #F2DDDD), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.faq-side-bg-2 {
  background: linear-gradient(0deg, #EBDCE5, #EBDCE5), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.bg-pink {
  background: linear-gradient(0deg, rgba(255, 0, 79, 0.1), rgba(255, 0, 79, 0.1)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.bg-thank-you {
  background: linear-gradient(180deg, rgba(255, 0, 79, 0.2) 0%, rgba(255, 0, 79, 0.05) 100%);
}

.bg-orange {
  background: linear-gradient(0deg, #FD8D14, #FD8D14);
}

.bg-green {
  background: linear-gradient(0deg, #00BA00, #00BA00);
}

.line-h-32px p {
  line-height: 32px !important;
}

.readonly {
  color: #c4c4c4;
}

.readonly:focus {
  border: 1px solid #9A9A9A !important;
  color: #c4c4c4;
}

.bg-none {
  background: none !important;
}

.bg-footer-subscribe {
  background: linear-gradient(180deg, rgba(255, 0, 79, 0.1) 0%, rgba(255, 0, 79, 0.5) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.muted-md {
  color: #9A9A9A;
}

.muted-lg {
  color: #4E4E4E;
}

.letter-spacing-none {
  letter-spacing: 0px !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.sign-shadow {
  box-shadow: 0px 2px 12px 0px #F5E5E5;
}

.w-max-content {
  width: max-content !important;
}

.opacity-60-hover {
  opacity: 0;
  transition: all 0.5s ease;
}

.opacity-60-hover:hover {
  opacity: 0.6 !important;
}

.custom-sadow-1 {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
}

.radius-10px {
  border-radius: 10px;
}

.base-hover:hover {
  color: rgb(255, 0, 79);
}

.text-base {
  color: rgb(255, 0, 79);
}

.text-red {
  color: red !important;
}

.text-custom-muted {
  color: #545F71 !important;
}

.list-style-none {
  list-style: none !important;
}

.transform-to-bottom-50 {
  transform: translateY(-50px) !important;
}

.width-60px {
  width: 60px;
}

.width-30px {
  width: 30px;
}

.sign-up-bg-1 {
  background: linear-gradient(0deg, rgba(254, 202, 202, 0.4), rgba(254, 202, 202, 0.4)), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.sign-up-bg-2 {
  background: linear-gradient(0deg, rgba(235, 193, 218, 0.4), rgba(235, 193, 218, 0.4)), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.sign-up-bg-3 {
  background: linear-gradient(0deg, rgba(255, 171, 221, 0.4), rgba(255, 171, 221, 0.4)), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.cursor-pointer {
  cursor: pointer;
}

.noHover {
  pointer-events: none;
}

.sign-up-bg-4 {
  background: linear-gradient(0deg, rgba(238, 171, 255, 0.4), rgba(238, 171, 255, 0.4)), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.twentytwenty-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6 !important;
}

.top-25 {
  top: 25%;
}

top-75 {
  top: 75%;
}

.start-5 {
  left: 5%;
}

.start-7 {
  left: 7%;
}

.start-10 {
  left: 10%;
}

.start-15 {
  left: 15%;
}

.start-25 {
  left: 25%;
}

.start-75 {
  left: 75%;
}

.end-25 {
  right: 25%;
}

.end-75 {
  right: 75%;
}

.center-absolute {
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.bg-base {
  background-color: rgb(255, 0, 79);
}

.rounded-10px {
  border-radius: 10px !important;
}

.rounded-top-10px {
  border-radius: 10px 10px 0px 0px !important;
}

.rounded-bottom-10px {
  border-radius: 0px 0px 10px 10px !important;
}

.rounded-top-10px {
  border-radius: 10px 10px 0px 0px !important;
}

.text-indent-30px {
  text-indent: 30px;
}

.p-7px {
  padding: 7px;
}

.bg-light-custom {
  background-color: #f7f7f7 !important;
}

.border-custom-muted {
  border: 1px solid #9A9A9A;
}

.border-1-base-color {
  border: 1px solid rgb(255, 0, 79) !important;
}

.border-bottom-sm-muted {
  border-bottom: 1px solid #D9D9D9;
}

.border-black {
  border: 1px solid black !important;
}

.border-dashed-sm-muted {
  border: 1px dashed #D9D9D9 !important;
}

.border-5-base-color {
  border: 5px solid rgb(255, 0, 79) !important;
}

.w-225px {
  width: 225px;
}

.w-28px {
  width: 28px !important;
}

.w-18px {
  width: 18px !important;
}

.h-225px {
  height: 225px !important;
}

.h-300px {
  height: 300px !important;
}

.h-500px {
  height: 500px !important;
}

.h-18px {
  height: 18px !important;
}

.h-28px {
  height: 28px !important;
}

.text-decoration-none {
  text-decoration: none;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 0.9rem !important;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.fs-8 {
  font-size: 0.7rem !important;
}

.fs-9 {
  font-size: 0.6rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-11 {
  font-size: 0.4rem !important;
}

.fs-12 {
  font-size: 0.3rem !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-18px {
  font-size: 18px !important;
}

.fs-23px {
  font-size: 23px !important;
}

.w-25 {
  width: 25% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100px {
  width: 100px !important;
}

.w-125px {
  width: 125px;
}

.h-125px {
  height: 125px;
}

.w-150px {
  width: 150px;
}

.h-150px {
  height: 150px;
}

.w-175px {
  width: 175px;
}

.h-175px {
  height: 175px;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.mt-6 {
  margin-top: 3rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

.mt-8 {
  margin-top: 4rem;
}

.mt-9 {
  margin-top: 4.5rem;
}

.mt-10 {
  margin-top: 5rem;
}

.mt-11 {
  margin-top: 5.5rem;
}

.mt-12 {
  margin-top: 6rem;
}

.mt-13 {
  margin-top: 6.5rem;
}

.mt-14 {
  margin-top: 7rem;
}

.mt-15 {
  margin-top: 7.5rem;
}

@media (min-width: 576px) {
  .fs-sm-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-sm-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
  .fs-sm-4 {
    font-size: 1.25rem !important;
  }
  .fs-sm-5 {
    font-size: 1.15rem !important;
  }
  .fs-sm-6 {
    font-size: 1.075rem !important;
  }
  .fs-sm-7 {
    font-size: 0.95rem !important;
  }
  .fs-sm-8 {
    font-size: 0.85rem !important;
  }
  .fs-sm-9 {
    font-size: 0.75rem !important;
  }
  .fs-sm-10 {
    font-size: 0.5rem !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .h-sm-480px {
    height: 480px !important;
  }
  .mt-sm-0 {
    margin-top: 0rem;
  }
  .mt-sm-1 {
    margin-top: 0.5rem;
  }
  .mt-sm-2 {
    margin-top: 1rem;
  }
  .mt-sm-3 {
    margin-top: 1.5rem;
  }
  .mt-sm-4 {
    margin-top: 2rem;
  }
  .mt-sm-5 {
    margin-top: 2.5rem;
  }
  .mt-sm-6 {
    margin-top: 3rem;
  }
  .mt-sm-7 {
    margin-top: 3.5rem;
  }
  .mt-sm-8 {
    margin-top: 4rem;
  }
  .mt-sm-9 {
    margin-top: 4.5rem;
  }
  .mt-sm-10 {
    margin-top: 5rem;
  }
}
@media (min-width: 768px) {
  .fs-md-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-md-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
  .fs-md-4 {
    font-size: 1.25rem !important;
  }
  .fs-md-5 {
    font-size: 1.15rem !important;
  }
  .fs-md-6 {
    font-size: 1.075rem !important;
  }
  .fs-md-7 {
    font-size: 0.95rem !important;
  }
  .fs-md-8 {
    font-size: 0.85rem !important;
  }
  .fs-md-9 {
    font-size: 0.75rem !important;
  }
  .fs-md-10 {
    font-size: 0.5rem !important;
  }
  .fs-md-36px {
    font-size: 36px !important;
  }
  .fs-md-60px {
    font-size: 60px !important;
  }
  .w-md-200px {
    width: 200px !important;
  }
  .h-md-200px {
    height: 200px !important;
  }
  .h-md-645px {
    height: 645px !important;
  }
  .w-md-225px {
    width: 225px !important;
  }
  .h-md-225px {
    height: 225px !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .h-md-550px {
    height: 550px !important;
  }
  .start-md-7 {
    left: 7% !important;
  }
  .mt-md-0 {
    margin-top: 0rem;
  }
  .mt-md-1 {
    margin-top: 0.5rem;
  }
  .mt-md-2 {
    margin-top: 1rem;
  }
  .mt-md-3 {
    margin-top: 1.5rem;
  }
  .mt-md-4 {
    margin-top: 2rem;
  }
  .mt-md-5 {
    margin-top: 2.5rem;
  }
  .mt-md-6 {
    margin-top: 3rem;
  }
  .mt-md-7 {
    margin-top: 3.5rem;
  }
  .mt-md-8 {
    margin-top: 4rem;
  }
  .mt-md-9 {
    margin-top: 4.5rem;
  }
  .mt-md-10 {
    margin-top: 5rem;
  }
}
@media (min-width: 992px) {
  .fs-lg-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
  .fs-lg-4 {
    font-size: 1.25rem !important;
  }
  .fs-lg-5 {
    font-size: 1.15rem !important;
  }
  .fs-lg-6 {
    font-size: 1.075rem !important;
  }
  .fs-lg-7 {
    font-size: 0.95rem !important;
  }
  .fs-lg-8 {
    font-size: 0.85rem !important;
  }
  .fs-lg-9 {
    font-size: 0.75rem !important;
  }
  .fs-lg-10 {
    font-size: 0.5rem !important;
  }
  .w-lg-225px {
    width: 225px !important;
  }
  .h-lg-225px {
    height: 225px !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .pe-lg-7 {
    padding-right: 3.5rem;
  }
  .h-lg-600px {
    height: 600px !important;
  }
  .mt-lg-0 {
    margin-top: 0rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-1 {
    margin-top: 0.5rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-2 {
    margin-top: 1rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-3 {
    margin-top: 1.5rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-4 {
    margin-top: 2rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-5 {
    margin-top: 2.5rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-6 {
    margin-top: 3rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-7 {
    margin-top: 3.5rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-8 {
    margin-top: 4rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-9 {
    margin-top: 4.5rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .mt-lg-10 {
    margin-top: 5rem;
  }
  .px-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-16 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .transform-to-bottom-lg-95 {
    transform: translateY(-95px) !important;
  }
  .bg-lg-shape {
    background-image: url("section_bg_shape_1.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
@media (min-width: 1200px) {
  .fs-xl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
  .fs-xl-4 {
    font-size: 1.25rem !important;
  }
  .fs-xl-5 {
    font-size: 1.15rem !important;
  }
  .fs-xl-6 {
    font-size: 1.075rem !important;
  }
  .fs-xl-7 {
    font-size: 0.95rem !important;
  }
  .fs-xl-8 {
    font-size: 0.85rem !important;
  }
  .fs-xl-9 {
    font-size: 0.75rem !important;
  }
  .fs-xl-10 {
    font-size: 0.5rem !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .h-xl-750px {
    height: 750px !important;
  }
  .mt-xl-0 {
    margin-top: 0rem;
  }
  .mt-xl-1 {
    margin-top: 0.5rem;
  }
  .mt-xl-2 {
    margin-top: 1rem;
  }
  .mt-xl-3 {
    margin-top: 1.5rem;
  }
  .mt-xl-4 {
    margin-top: 2rem;
  }
  .mt-xl-5 {
    margin-top: 2.5rem;
  }
  .mt-xl-6 {
    margin-top: 3rem;
  }
  .mt-xl-7 {
    margin-top: 3.5rem;
  }
  .mt-xl-8 {
    margin-top: 4rem;
  }
  .mt-xl-9 {
    margin-top: 4.5rem;
  }
  .mt-xl-10 {
    margin-top: 5rem;
  }
}
@media (min-width: 1400px) {
  .fs-xxl-6hx {
    font-size: calc(4rem + 0.8vw) !important;
  }
  .fs-xxl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xxl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xxl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }
  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }
  .fs-xxl-5 {
    font-size: 1.15rem !important;
  }
  .fs-xxl-6 {
    font-size: 1.075rem !important;
  }
  .fs-xxl-7 {
    font-size: 0.95rem !important;
  }
  .fs-xxl-8 {
    font-size: 0.85rem !important;
  }
  .fs-xxl-9 {
    font-size: 0.75rem !important;
  }
  .fs-xxl-10 {
    font-size: 0.5rem !important;
  }
  .fs-xxl-100px {
    font-size: 100px !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .h-xxl-600px {
    height: 600px !important;
  }
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: black !important;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80%;
  height: 80%;
  background: rgb(255, 0, 79);
  opacity: 0.9;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100%;
  height: 100%;
  background: rgb(255, 0, 79);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0.8;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.custom-card-hover {
  background-color: #f7f7f7;
  position: relative;
  border-radius: 10px;
}
.custom-card-hover:after {
  position: absolute;
  top: 0px;
  content: "";
  height: 0px;
  background-color: rgb(255, 0, 79);
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  z-index: 2;
  transition: all 0.3s ease;
  opacity: 0;
}
.custom-card-hover:hover:after {
  height: 5px;
  opacity: 1;
}

.custom-card-active {
  background-color: #f7f7f7;
  position: relative;
  border-radius: 10px 10px;
}
.custom-card-active:after {
  position: absolute;
  top: 0px;
  content: "";
  height: 10px;
  background-color: rgb(255, 0, 79);
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  z-index: 2;
  transition: all 0.3s ease;
}

.free-programm-card .overlay .mask {
  background-color: rgba(0, 0, 0, 0);
}
.free-programm-card .overlay .mask * {
  opacity: 0;
}
.free-programm-card:hover .overlay {
  transition: all 0.15s ease;
  transform: translateY(-4px);
}
.free-programm-card:hover .overlay .mask {
  transition: all 0.5s ease;
  background-color: rgba(0, 0, 0, 0.3);
}
.free-programm-card:hover .overlay .mask * {
  transition: all 0.5s ease;
  opacity: 1;
}

.bg-popular {
  background: linear-gradient(0deg, rgba(255, 0, 79, 0.1), rgba(255, 0, 79, 0.1)), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
}

.ribbon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ribbon-right {
  left: 28px;
}
.ribbon-right span {
  transform: translateX(-50%) translateY(-40%) rotate(-90deg);
  top: 40%;
  left: 50%;
  font-size: 12px;
}

.ribbon-left {
  right: 25px;
}
.ribbon-left span {
  transform: translateX(-50%) translateY(-40%) rotate(-90deg);
  top: 40%;
  left: 50%;
  font-size: 12px;
}

input {
  display: block;
  width: 100%;
  padding: 5px;
  outline: none;
  margin-bottom: 15px;
  border: 1px solid #9A9A9A;
  box-sizing: border-box;
}

.password-strength-group .password-strength-meter {
  width: 100%;
  transition: height 0.3s;
  display: flex;
  justify-content: stretch;
}
.password-strength-group .password-strength-meter .meter-block {
  height: 4px;
  background: #ccc;
  margin-right: 6px;
  flex-grow: 1;
}
.password-strength-group .password-strength-meter .meter-block:last-child {
  margin: 0;
}
.password-strength-group .password-strength-message {
  font-weight: 20px;
  height: 1em;
  text-align: right;
  transition: all 0.5s;
  margin-top: 3px;
  position: relative;
}
.password-strength-group .password-strength-message .message-item {
  font-size: 12px;
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
}
.password-strength-group[data-strength="1"] .meter-block:nth-child(-n+1) {
  background: #cc3d04;
}
.password-strength-group[data-strength="1"] .message-item:nth-child(1) {
  opacity: 1;
}
.password-strength-group[data-strength="2"] .meter-block:nth-child(-n+2) {
  background: #ffc43b;
}
.password-strength-group[data-strength="2"] .message-item:nth-child(2) {
  opacity: 1;
}
.password-strength-group[data-strength="3"] .meter-block:nth-child(-n+3) {
  background: #9ea60a;
}
.password-strength-group[data-strength="3"] .message-item:nth-child(3) {
  opacity: 1;
}
.password-strength-group[data-strength="4"] .meter-block:nth-child(-n+4) {
  background: #289116;
}
.password-strength-group[data-strength="4"] .message-item:nth-child(4) {
  opacity: 1;
}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked) > label:before {
  content: "★ ";
}

.rate > input:checked ~ label {
  color: rgb(253, 216, 20);
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: rgb(253, 216, 20);
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: rgb(253, 216, 20);
}

#toTopButton {
  display: none;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  border: none;
  outline: none;
  background-color: rgba(255, 0, 79, 0.8);
}
#toTopButton:hover {
  background-color: rgb(255, 0, 79);
}

.buttons__burger {
  --size: 3rem;
  --clr: #000;
  width: var(--size);
  height: calc(0.7 * var(--size));
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 1046;
  position: absolute;
}

.buttons__burger #burger {
  display: none;
  width: 100%;
  height: 100%;
}

.buttons__burger span {
  display: block;
  position: absolute;
  width: 100%;
  border-radius: 0.5rem;
  border: 3px solid var(--clr);
  background-color: var(--clr);
  transition: 0.15s ease-in-out;
}

.buttons__burger span:nth-of-type(1) {
  top: 0;
  right: 0;
  transform-origin: right center;
}

.buttons__burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
}

.buttons__burger span:nth-of-type(3) {
  top: 100%;
  right: 0;
  transform-origin: right center;
  transform: translateY(-100%);
}

.buttons__burger #burger:checked ~ span:nth-of-type(1) {
  transform: translateY(-30%) rotate(40deg);
  width: 50%;
  top: 50%;
}

.buttons__burger #burger:checked ~ span:nth-of-type(3) {
  transform: translateY(-70%) rotate(-40deg);
  width: 50%;
  top: 50%;
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition-duration: 0.2s;
}

.bars {
  width: 100%;
  height: 4px;
  background-color: rgb(255, 0, 79);
  border-radius: 5px;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle .bars {
  margin-left: 13px;
}

#checkbox:checked + .toggle #bar2 {
  transform: rotate(135deg);
  margin-left: 0;
  transform-origin: center;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle #bar1 {
  transform: rotate(45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

#checkbox:checked + .toggle #bar3 {
  transform: rotate(-45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

.breadcrumbWrapper {
  background: linear-gradient(273.72deg, rgba(255, 0, 79, 0.2) 0.49%, rgba(255, 0, 79, 0) 53.05%), linear-gradient(93.65deg, rgba(255, 0, 79, 0.2) 0.25%, rgba(255, 0, 79, 0) 52.99%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
}
.breadcrumbWrapper .breadcrumb .active {
  color: rgb(255, 0, 79);
}

.custom-header {
  background: linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, rgba(255, 0, 79, 0.2), rgba(255, 0, 79, 0.2));
}
.custom-header .line {
  border-bottom: 1px solid rgba(255, 0, 79, 0.2);
}
.custom-header #top-header-right-side .link-underline {
  color: black;
  transition: color 0.3s ease;
}
.custom-header #top-header-right-side .link-underline:hover {
  color: rgb(255, 0, 79);
}
.custom-header #navbarSupportedContent ul li.nav-item a:not(.join-now-btn) {
  transition: color 0.3s ease;
}
.custom-header #navbarSupportedContent ul li.nav-item a:hover {
  color: rgb(255, 0, 79);
}
.custom-header #navbarSupportedContent ul li.nav-item .active {
  color: rgb(255, 0, 79) !important;
  position: relative;
}
.custom-header #navbarSupportedContent ul li.nav-item .active::before {
  position: absolute;
  width: 7px;
  height: 1px;
  background-color: rgb(255, 0, 79);
  content: "";
  top: 50%;
  left: -5px;
}
.custom-header button.navbar-toggler .bi-x-lg {
  font-weight: 800;
  font-size: 1.75rem;
}

footer {
  background: linear-gradient(0deg, rgba(255, 0, 79, 0.5) -131.23%, rgba(255, 0, 79, 0) 100%);
}
footer .container-fluid {
  border-top: 1px solid rgba(245, 1, 0, 0.1019607843);
  border-bottom: 1px solid rgba(245, 1, 0, 0.1019607843);
}
footer #subscribeForm .btn-outline-secondary {
  color: white;
  right: 10px;
  bottom: 7px;
}
footer #subscribeForm #subscribe {
  border: none !important;
}
footer #subscribeForm #subscribe::placeholder {
  color: black;
  opacity: 1;
  font-weight: bold;
  font-size: 1rem;
}
footer .top-section {
  background: linear-gradient(180deg, rgba(255, 0, 79, 0.1) 0%, rgba(255, 0, 79, 0.5) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.story {
  width: 115px;
  overflow: hidden;
  position: relative;
}
.story img {
  width: 78px;
  height: auto;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.story svg {
  fill: none;
  stroke: rgb(255, 0, 79);
  stroke-width: 1px;
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: loading 4500ms ease-in-out infinite alternate;
}

@keyframes loading {
  100% {
    stroke: rgb(255, 0, 79);
    stroke-dasharray: 10;
    transform: rotate(200deg);
  }
}
@media screen and (max-width: 370px) {
  .story img {
    width: 78px;
    transform: translate(-50%, -52%);
  }
}
#storyModal iframe {
  display: block;
  aspect-ratio: 16/9;
  height: 100%;
}

#sara-popfit-section {
  background: linear-gradient(180deg, rgba(255, 0, 79, 0.1) 0%, rgba(255, 0, 79, 0) 50.52%, rgba(255, 0, 79, 0.079) 100%);
}
#sara-popfit-section .text-side .fs-1 {
  font-size: calc(3.375rem + 1.5vw) !important;
}
#sara-popfit-section .text-side h1 {
  text-shadow: -1px 1px 0 #000, 1px 1px 0 rgb(255, 0, 79), 1px -1px 0 rgb(255, 0, 79), -1px -1px 0 rgb(255, 0, 79);
  color: white;
  letter-spacing: 0.225em;
  font-weight: bold;
  opacity: 0.5;
}
#sara-popfit-section .text-side p {
  font-weight: 500;
  line-height: 28px;
}
#sara-popfit-section .text-side a.btn {
  font-size: 20px;
}
#sara-popfit-section .counter-side .counter {
  border-bottom: 4px solid rgb(255, 0, 79);
  border-radius: 25px;
}
#sara-popfit-section .counter-side .counter-number {
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: 0.03em;
  text-align: left;
}
#sara-popfit-section .counter-side .counter-number span {
  font-size: 36px;
  font-weight: 400;
}
@keyframes scroll-en {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes scroll-ar {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
#sara-popfit-section .slider {
  margin: auto;
  overflow: hidden;
  position: relative;
}
#sara-popfit-section .slider::before, #sara-popfit-section .slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
#sara-popfit-section .slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
#sara-popfit-section .slider::before {
  left: 0;
  top: 0;
}
#sara-popfit-section .slider .slide-track-en, #sara-popfit-section .slider .slide-track-ar {
  display: flex;
  width: 3500px;
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
}
#sara-popfit-section .slider .slide-track-en {
  animation: scroll-en 15s linear infinite;
}
#sara-popfit-section .slider .slide-track-ar {
  animation: scroll-ar 15s linear infinite;
}

.heroSliderSection .customButtonRow {
  background: linear-gradient(180deg, rgba(255, 0, 79, 0.12) 0%, rgba(255, 0, 79, 0.02) 54.69%, rgba(255, 0, 79, 0.12) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
}
.heroSliderSection .heroSlider .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 20px;
  background-color: white;
  opacity: 1;
  padding: 10px;
  border: 1px solid rgb(255, 0, 79);
}
.heroSliderSection .heroSlider .swiper-pagination-bullet-active {
  padding: 10px 20px;
  background: rgb(255, 0, 79) !important;
}

#avatarPreview {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.avatar-upload,
.image-upload {
  display: inline-block;
  position: relative;
}

.avatar-upload .avatar-edit,
.image-upload .image-edit {
  position: absolute;
  z-index: 1;
}

.avatar-upload .avatar-edit {
  bottom: 50px;
}

.right {
  right: 32px;
}

.left {
  left: 32px;
}

.avatar-upload .avatar-edit input,
.image-upload .image-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label:hover,
.image-upload .image-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after,
.image-upload .image-edit input + label:after {
  content: "+";
  color: white;
  font-size: 1.7rem !important;
  border: 3px solid white !important;
  background-color: #ff004f;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-upload .preview,
.image-upload .preview {
  width: 130px;
  height: 130px;
  position: relative;
  border: 6px solid white;
}

.avatar-upload .preview {
  border-radius: 100%;
}

.avatar-upload .preview > div,
.image-upload .preview > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.preview > div,
.invalid-file {
  display: none;
}

#planAccordion .hide-data .accordion-items-image-box, #planAccordion .hide-data .tt, #planAccordion .hide-data .target {
  display: none !important;
}
#planAccordion .done-circle-mark {
  width: 15px;
  height: 15px;
}

#exercisesContainer .show-target {
  display: none !important;
}
#exercisesContainer .arrow-image {
  transition: all 0.3s ease;
  transform: rotate(180deg);
}
#exercisesContainer .handle-visibility .arrow-image {
  transition: all 0.3s ease;
  transform: rotate(0deg);
}
#exercisesContainer .handle-visibility .hide-target {
  display: none !important;
}
#exercisesContainer .handle-visibility .show-target {
  display: block !important;
}

@keyframes slide {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}
.animation-arrow-path .animation-arrow {
  animation: slide 2.5s linear infinite;
}
.animation-arrow-path .border-dashed {
  border: 1px dashed #aaa !important;
}
@keyframes animation-shadow {
  0% {
    box-shadow: 0px 0px 0px 0px #ff004f;
  }
  50% {
    box-shadow: 0px 0px 4px 0px #ff004f;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #ff004f;
  }
}
.animation-arrow-path .animation-shadow {
  animation: animation-shadow 1s linear infinite;
  z-index: 30;
}
.animation-arrow-path .custom-checkBox {
  position: relative;
  z-index: 10;
  opacity: 1 !important;
}
.animation-arrow-path .custom-checkBox:checked {
  background-color: rgb(255, 0, 79) !important;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

#questions .accordion-button::after {
  background-image: none !important;
  transition: none !important;
  content: "+";
  font-weight: 100;
  font-size: 3rem;
  width: max-content !important;
  height: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
#questions .accordion-button:not(.collapsed)::after {
  content: "-";
  height: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  font-size: 3.2rem;
  width: max-content !important;
  background-image: none !important;
  transform: none !important;
}

.completedOnButtons button {
  color: white;
}
.completedOnButtons button.notActive {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.completedOnButtons .red {
  background-color: #ff004f;
  border: 1px solid #ff004f;
}
.completedOnButtons .green {
  background-color: #00BA00;
  border: 1px solid #00BA00;
}
.completedOnButtons .yellow {
  background-color: #FD8D14;
  border: 1px solid #FD8D14;
}

.icon-card {
  border: 1px solid #f7f7f7;
}
.icon-card:hover {
  background-color: white !important;
  border: 1px solid black;
}
.icon-card:hover img {
  transform: scale(1.15);
}

.list .item:hover {
  background-color: white;
  color: rgb(255, 0, 79);
}

.border-bottom-dashed {
  border-bottom: 1px dashed #9A9A9A;
}

.sidebar .active {
  background-color: white;
  color: rgb(255, 0, 79);
}
.sidebar .active .fa-chevron-right {
  color: rgb(255, 0, 79) !important;
}
.sidebar .bg-upgrade {
  background: linear-gradient(275.09deg, rgba(255, 0, 79, 0.2) 1.82%, rgba(255, 0, 79, 0) 53.92%), linear-gradient(95.8deg, rgba(255, 0, 79, 0.2) 2.57%, rgba(255, 0, 79, 0) 54.61%), linear-gradient(176.32deg, rgba(255, 0, 79, 0.1) -155.84%, rgba(255, 0, 79, 0) 131.75%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border-radius: 20px;
}

#paymentContainer #paymentMethods .accordion-item {
  border-radius: 10px !important;
}
#paymentContainer #paymentMethods .accordion-item .accordion-button {
  border-radius: 10px !important;
}
#paymentContainer #paymentMethods .accordion-item .accordion-button .fa-minus {
  display: none;
  border: 1px solid black;
  color: black;
  border-radius: 3px;
  padding: 2px;
  font-size: 13px;
}
#paymentContainer #paymentMethods .accordion-item .accordion-button .fa-plus {
  display: block;
  border: 1px solid #9A9A9A;
  color: #9A9A9A;
  border-radius: 3px;
  padding: 2px;
  font-size: 13px;
}
#paymentContainer #paymentMethods .accordion-item .accordion-button:not(.collapsed) .fa-minus {
  display: block;
}
#paymentContainer #paymentMethods .accordion-item .accordion-button:not(.collapsed) .fa-plus {
  display: none;
}
#paymentContainer #paymentMethods .accordion-item .accordion-button::after {
  content: "";
  background-image: none;
  border: 5px solid #A9A9A9;
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
#paymentContainer #paymentMethods .plusButton::after {
  display: none;
}
#paymentContainer #paymentMethods .minusButton::after {
  display: none;
}
#paymentContainer #paymentMethods .accordion-button:not(.collapsed)::after {
  border: 5px solid rgb(255, 0, 79);
}
#paymentContainer #newMe .accordion-item {
  background: linear-gradient(0deg, rgba(255, 0, 79, 0.1), rgba(255, 0, 79, 0.1)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
}
#paymentContainer #billingSummary .subtotal {
  border-bottom: 1px solid #4E4E4E;
}

.recipeSliderRow .swiper-button-prev, .recipeSliderRow .swiper-button-next {
  background-color: rgba(255, 0, 79, 0.5);
  padding: 0px 18px;
  border-radius: 6px;
}
.recipeSliderRow .swiper-button-prev:hover, .recipeSliderRow .swiper-button-next:hover {
  background-color: rgb(255, 0, 79);
}
.recipeSliderRow .swiper-button-next:after, .recipeSliderRow .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 2rem !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: white !important;
}
.recipeSliderRow .swiper-thumbs .swiper-slide-visible {
  opacity: 0.5;
}
.recipeSliderRow .swiper-thumbs .swiper-slide-thumb-active {
  opacity: 1 !important;
}

.about-container .awards-achievements-card {
  border-radius: 10px;
  transition: all 0.2s ease;
}
.about-container .awards-achievements-card:hover {
  background-color: #f7f7f7;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
}
.about-container .tv-card img {
  filter: grayscale(100%);
  transition: all 0.2s ease;
}
.about-container .tv-card:hover img {
  filter: none;
  transform: scale(1.03);
}
.about-container .circle-image-card .mask {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.2s ease;
}
.about-container .circle-image-card .mask:hover {
  border: 5px solid rgb(255, 0, 79);
}
.about-container .swiper-pagination-bullet-active {
  background-color: rgb(255, 0, 79);
}
.about-container .youtube-card {
  border: 1px solid #f7f7f7;
  transition: all 0.2s ease;
}
.about-container .youtube-card:hover {
  border-color: rgb(255, 0, 79);
  transform: scale(1.03);
}

.why-new-me-container .why-new-me-card .bg-1 {
  background: linear-gradient(0deg, #F2DDDD, #F2DDDD), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}
.why-new-me-container .why-new-me-card .bg-2 {
  background: linear-gradient(0deg, #EBDCE5, #EBDCE5), linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}
.why-new-me-container .paymentOptionCard {
  transition: all 0.1s ease;
}
.why-new-me-container .paymentOptionCard:hover {
  transform: scale(1.5);
}
.why-new-me-container .ratingCard {
  background-color: white;
  transition: all 0.1s ease;
}
.why-new-me-container .ratingCard:hover {
  background-color: #f7f7f7;
}

/*@import "sass/bootstrap";*/