.breadcrumbWrapper{
    background: linear-gradient(273.72deg, rgba(255, 0, 79, 0.2) 0.49%, rgba(255, 0, 79, 0) 53.05%),
    linear-gradient(93.65deg, rgba(255, 0, 79, 0.2) 0.25%, rgba(255, 0, 79, 0) 52.99%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    .breadcrumb {
        .active{
            color: $text-base;
        }
    }

}
