.story {
    width: 115px;
    overflow: hidden;
    position: relative;

    img {
        width: 78px;
        height: auto;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    svg {
        fill: none;
        stroke: $base-color-1;
        stroke-width: 1px;
        stroke-dasharray: 1;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: loading 4500ms ease-in-out infinite alternate;
    }
}

@keyframes loading {
    100% {
        stroke: $base-color-1;
        stroke-dasharray: 10;
        transform: rotate(200deg);
    }
}

@media screen and (max-width: 370px) {
    .story {
        img {
            width: 78px;
            transform: translate(-50%, -52%);
        }
    }

}

#storyModal {
    iframe{
        display: block;
        aspect-ratio: 16/9;
        height: 100%;
    }
}
