.sidebar {
    .active {
        background-color: white;
        color: $base-color-1;
        .fa-chevron-right {
            color: $base-color-1 !important;
        }
    }
    .bg-upgrade{
        background: linear-gradient(275.09deg, rgba(255, 0, 79, 0.2) 1.82%, rgba(255, 0, 79, 0) 53.92%),
        linear-gradient(95.8deg, rgba(255, 0, 79, 0.2) 2.57%, rgba(255, 0, 79, 0) 54.61%),
        linear-gradient(176.32deg, rgba(255, 0, 79, 0.1) -155.84%, rgba(255, 0, 79, 0) 131.75%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border-radius: 20px;

    }
}

