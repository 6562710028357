.heroSliderSection{
        .customButtonRow{
            background: linear-gradient(180deg, rgba(255, 0, 79, 0.12) 0%, rgba(255, 0, 79, 0.02) 54.69%, rgba(255, 0, 79, 0.12) 100%),
            linear-gradient(0deg, #FFFFFF, #FFFFFF);
        }
    .heroSlider {
        .swiper-pagination-bullet {
            width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px));
            height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
            display: inline-block;
            border-radius:20px;
            background-color: white;
            opacity: 1;
            padding: 10px;
            border: 1px solid $base-color-1;
        }
        .swiper-pagination-bullet-active {
            padding:10px 20px ;
            background: $base-color-1 !important;
        }

    }
}

