#avatarPreview{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.avatar-upload,
.image-upload {
    display: inline-block;
    position: relative;
}
.avatar-upload .avatar-edit,
.image-upload .image-edit {
    position: absolute;
    z-index: 1;
}
.avatar-upload .avatar-edit {
    bottom: 50px;
}
.right{
    right: 32px;
}
.left{
    left: 32px;
}
.avatar-upload .avatar-edit input,
.image-upload .image-edit input {
    display: none;
}

.avatar-upload .avatar-edit input + label:hover,
.image-upload .image-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after,
.image-upload .image-edit input + label:after {
    content: "+";
    color: white;
    font-size: 1.7rem !important;
    border: 3px solid white !important;
    background-color: #ff004f;
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.avatar-upload .preview,
.image-upload .preview {
    width: 130px;
    height: 130px;
    position: relative;
    border: 6px solid white;
}
.avatar-upload .preview {
    border-radius: 100%;
}
.avatar-upload .preview > div,
.image-upload .preview > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.preview > div,
.invalid-file {
    display: none;
}
