.custom-header {
    background: linear-gradient(0deg, #F8F8F8, #F8F8F8),
    linear-gradient(0deg, rgba(255, 0, 79, 0.2), rgba(255, 0, 79, 0.2));

   .line{
       border-bottom: 1px solid rgba(255, 0, 79, 0.2);

   }

    #top-header-right-side {
        .link-underline {
            color: black;
            transition: color .3s ease;
        }

        .link-underline:hover {
            color: $base-color-1;
        }
    }
    #navbarSupportedContent {
        ul {
            li.nav-item {
                a:not(.join-now-btn) {
                    transition: color .3s ease;
                }

                a:hover {
                    color: $base-color-1;
                }

                .active {
                    color: $base-color-1 !important;
                    position: relative;
                }

                .active::before {
                    position: absolute;
                    width: 7px;
                    height: 1px;
                    background-color: $base-color-1;
                    content: '';
                    top: 50%;
                    left: -5px;
                }
            }
        }
    }
    button.navbar-toggler{
        .bi-x-lg{
            font-weight: 800;
            font-size: 1.75rem;
        }
    }
}
