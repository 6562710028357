/*HEADER*/


$base-color-1: rgb(255, 0, 79);
$bg-base:$base-color-1;
$text-base:$base-color-1;
$custom-light:#f7f7f7;
$text-custom-muted:#545F71;
$muted-sm:#D9D9D9;
$muted-md:#9A9A9A;
$muted-lg:#4E4E4E;
$custom-shadow:0px 2px 12px 0px #00000033;

