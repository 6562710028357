.recipeSliderRow{
    .swiper-button-prev,.swiper-button-next{
        background-color:  rgba(255,0,79,.5);
        padding:0px 18px;
        border-radius: 6px;
        &:hover{
            background-color:  rgba(255,0,79,1);
        }
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        font-family: swiper-icons;
        font-size: 2rem !important;
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
        color: white !important;

    }
    .swiper-thumbs{
        .swiper-slide-visible{
            opacity: .5;
        }
    }
    .swiper-thumbs{
        .swiper-slide-thumb-active{
            opacity: 1 !important;
        }
    }



}

