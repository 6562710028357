.about-container {

    .awards-achievements-card {
        border-radius: 10px;
        transition: all .2s ease;

        &:hover {
            background-color: $custom-light;
            box-shadow: $custom-shadow;
        }
    }

    .tv-card {
        img {
            filter: grayscale(100%);
            transition: all .2s ease;
        }

        &:hover {
            img {
                filter: none;
                transform: scale(1.03);
            }
        }
    }

    .circle-image-card {
        .mask{
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0);
            transition: all .2s ease;
            &:hover{
                border: 5px solid $base-color-1;
            }
        }


    }

     .swiper-pagination-bullet-active{
         background-color: $base-color-1;
     }
    .youtube-card{
        border: 1px solid #f7f7f7;
        transition: all .2s ease;
        &:hover{

            border-color: $base-color-1;
            transform: scale(1.03);

        }
    }
}
