.icon-card{
    border: 1px solid $custom-light;
    &:hover{
        background-color: white !important;
        border: 1px solid black;

        img{
            transform: scale(1.15);
        }

    }
}
