
  #sara-popfit-section {
    background: linear-gradient(180deg, rgba(255, 0, 79, 0.1) 0%, rgba(255, 0, 79, 0) 50.52%, rgba(255, 0, 79, 0.079) 100%);
    .text-side{
            .fs-1{font-size:calc(3.375rem + 1.5vw)!important}
        h1{
            text-shadow: -1px 1px 0 #000,
            1px 1px 0 $base-color-1,
            1px -1px 0 $base-color-1,
            -1px -1px 0 $base-color-1;
            color: white;
            letter-spacing:  0.225em;
            font-weight: bold;
            opacity: .5;
        }
        p{

            font-weight: 500;
            line-height: 28px;
        }
        a.btn{
            font-size: 20px;
        }
    }
    .counter-side{
        .counter{
            border-bottom: 4px solid $base-color-1;
            border-radius: 25px;

        }
        .counter-number{
            font-size: 60px;
            font-weight: 400;
            line-height: 60px;
            letter-spacing: 0.03em;
            text-align: left;
            span{
                font-size: 36px;
                font-weight: 400;
            }

        }
    }
      @mixin white-gradient {
          background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
      }



      $animationSpeed: 15s;
      @keyframes scroll-en {
          0% { transform: translateX(0); }
          100% { transform: translateX(-100%)}
      }
      @keyframes scroll-ar {
          0% { transform: translateX(0); }
          100% { transform: translateX(100%)}
      }
      .slider {
          margin: auto;
          overflow:hidden;
          position: relative;
          &::before,
          &::after {
              content: "";
              height: 100px;
              position: absolute;
              width: 200px;
              z-index: 2;
          }

          &::after {
              right: 0;
              top: 0;
              transform: rotateZ(180deg);
          }

          &::before {
              left: 0;
              top: 0;
          }

          .slide-track-en,.slide-track-ar{
              display: flex;
              width: calc(250px * 14);
              border-top:1px solid white !important;
              border-bottom:1px solid white !important;
          }
          .slide-track-en{
              animation: scroll-en $animationSpeed linear infinite;
          }
          .slide-track-ar{
              animation: scroll-ar $animationSpeed linear infinite;
          }
      }
}
