#toTopButton {
    display: none;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    border: none;
    outline: none;
    background-color: rgba(255, 0, 79, 0.8);
    &:hover {
        background-color: rgb(255, 0, 79);
    }
}


