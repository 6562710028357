#questions{
    .accordion-button::after {
        background-image:none !important;
        transition: none !important;
        content: "+";
        font-weight: 100;
        font-size: 3rem;
        width: max-content !important;
        height: 15px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .accordion-button:not(.collapsed)::after {
        content: '-';
        height: 15px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 100;
        font-size: 3.2rem;
        width: max-content !important;
        background-image: none !important;
        transform: none !important;
    }
}
