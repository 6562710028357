.base-hover {
    color: black;
    transition: color .3s ease;
}

.left-81 {
    left: 81% !important;
}


.right-81 {
    right: 81% !important;
}

.faq-nav{
    .nav-item {
        .active{
            border: 1px solid $base-color-1;
        }
    }
}

.faq-side-bg-1 {
    background: linear-gradient(0deg, #F2DDDD, #F2DDDD),
    linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);

}

.faq-side-bg-2 {
    background: linear-gradient(0deg, #EBDCE5, #EBDCE5),
    linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);

}

.bg-pink {
    background: linear-gradient(0deg, rgba(255, 0, 79, 0.1), rgba(255, 0, 79, 0.1)),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);

}

.bg-thank-you {
    background: linear-gradient(180deg, rgba(255, 0, 79, 0.2) 0%, rgba(255, 0, 79, 0.05) 100%);

}

.bg-orange {
    background: linear-gradient(0deg, #FD8D14, #FD8D14);

}

.bg-green {
    background: linear-gradient(0deg, #00BA00, #00BA00);

}

.line-h-32px p {
    line-height: 32px !important;
}

.readonly {
    color: #c4c4c4;
}

.readonly:focus {
    border: 1px solid #9A9A9A !important;
    color: #c4c4c4;
}

.bg-none {
    background: none !important;
}

.bg-footer-subscribe {
    background: linear-gradient(180deg, rgba(255, 0, 79, 0.1) 0%, rgba(255, 0, 79, 0.5) 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.muted-md {
    color: $muted-md;
}

.muted-lg {
    color: $muted-lg;
}

.letter-spacing-none {
    letter-spacing: 0px !important;
}

.opacity-1 {
    opacity: 1 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.sign-shadow {
    box-shadow: 0px 2px 12px 0px #F5E5E5;
}

.w-max-content {
    width: max-content !important;
}

.opacity-60-hover {
    opacity: 0;
    transition: all .5s ease;
}

.opacity-60-hover:hover {
    opacity: .6 !important;
}

.custom-sadow-1 {
    box-shadow: 0px 2px 12px 0px #00000033;
}

.radius-10px {
    border-radius: 10px;
}

.base-hover:hover {
    color: $base-color-1;
}

.text-base {
    color: $base-color-1;
}

.text-red {
    color: red !important;
}

.text-custom-muted {
    color: $text-custom-muted !important;
}

.list-style-none {
    list-style: none !important;
}

.transform-to-bottom-50 {
    transform: translateY(-50px) !important;
}

.width-60px {
    width: 60px;
}

.width-30px {
    width: 30px;
}

.sign-up-bg-1 {
    background: linear-gradient(0deg, rgba(254, 202, 202, 0.4), rgba(254, 202, 202, 0.4)),
    linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.sign-up-bg-2 {
    background: linear-gradient(0deg, rgba(235, 193, 218, 0.4), rgba(235, 193, 218, 0.4)),
    linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.sign-up-bg-3 {
    background: linear-gradient(0deg, rgba(255, 171, 221, 0.4), rgba(255, 171, 221, 0.4)),
    linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.cursor-pointer {
    cursor: pointer;
}

.noHover {
    pointer-events: none;
}

.sign-up-bg-4 {
    background: linear-gradient(0deg, rgba(238, 171, 255, 0.4), rgba(238, 171, 255, 0.4)),
    linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);
}

.twentytwenty-wrapper {
    width: 100% !important;
    height: 100% !important;
}

.z-index-5 {
    z-index: 5;
}

.z-index-6 {
    z-index: 6 !important;
}

.top-25 {
    top: 25%;
}

top-75 {
    top: 75%;
}

.start-5 {
    left: 5%;
}

.start-7 {
    left: 7%;
}

.start-10 {
    left: 10%;
}

.start-15 {
    left: 15%;
}

.start-25 {
    left: 25%;
}

.start-75 {
    left: 75%;
}

.end-25 {
    right: 25%;
}

.end-75 {
    right: 75%;
}

.center-absolute {
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.bg-base {
    background-color: $base-color-1;
}

.rounded-10px {
    border-radius: 10px !important;
}

.rounded-top-10px {
    border-radius: 10px 10px 0px 0px !important;
}

.rounded-bottom-10px {
    border-radius: 0px 0px 10px 10px !important;
}

.rounded-top-10px {
    border-radius: 10px 10px 0px 0px !important;
}

.text-indent-30px {
    text-indent: 30px;
}

.p-7px {
    padding: 7px;
}

.bg-light-custom {
    background-color: $custom-light !important;
}

.border-custom-muted {
    border: 1px solid $muted-md;
}

.border-1-base-color {
    border: 1px solid $base-color-1 !important;
}

.border-bottom-sm-muted {
    border-bottom: 1px solid $muted-sm;
}

.border-black {
    border: 1px solid black !important;
}

.border-dashed-sm-muted {
    border: 1px dashed $muted-sm !important;
}

.border-5-base-color {
    border: 5px solid $base-color-1 !important;
}

.w-225px {
    width: 225px;
}

.w-28px {
    width: 28px !important;
}

.w-18px {
    width: 18px !important;
}

.h-225px {
    height: 225px !important;
}

.h-300px {
    height: 300px !important;
}

.h-500px {
    height: 500px !important;
}

.h-18px {
    height: 18px !important;
}

.h-28px {
    height: 28px !important;
}

.text-decoration-none {
    text-decoration: none;
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw) !important
}

.fs-3 {
    font-size: calc(1.3rem + .6vw) !important
}

.fs-4 {
    font-size: calc(1.275rem + .3vw) !important
}

.fs-5 {
    font-size: 1.25rem !important
}

.fs-6 {
    font-size: .9rem !important
}

.fs-7 {
    font-size: .8rem !important
}

.fs-8 {
    font-size: .7rem !important
}

.fs-9 {
    font-size: .6rem !important
}

.fs-10 {
    font-size: .5rem !important
}

.fs-11 {
    font-size: .4rem !important
}

.fs-12 {
    font-size: .3rem !important
}

.fs-14px {
    font-size: 14px !important;
}

.fs-16px {
    font-size: 16px !important;
}

.fs-18px {
    font-size: 18px !important;
}

.fs-23px {
    font-size: 23px !important;
}

.w-25 {
    width: 25% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-100px {
    width: 100px !important;
}

.w-125px {
    width: 125px;
}

.h-125px {
    height: 125px;
}

.w-150px {
    width: 150px;
}

.h-150px {
    height: 150px;
}

.w-175px {
    width: 175px;
}

.h-175px {
    height: 175px;
}

.py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

@for $i from 6 through 15 {
    .mt-#{$i} {
        margin-top: $i*.5rem;
    }
}

@media (min-width: 576px) {
    .fs-sm-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
    }
    .fs-sm-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
    }
    .fs-sm-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
    }
    .fs-sm-4 {
        font-size: 1.25rem !important;
    }
    .fs-sm-5 {
        font-size: 1.15rem !important;
    }
    .fs-sm-6 {
        font-size: 1.075rem !important;
    }
    .fs-sm-7 {
        font-size: 0.95rem !important;
    }
    .fs-sm-8 {
        font-size: 0.85rem !important;
    }
    .fs-sm-9 {
        font-size: 0.75rem !important;
    }
    .fs-sm-10 {
        font-size: 0.5rem !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
    .w-sm-100 {
        width: 100% !important;
    }
    .h-sm-480px {
        height: 480px !important;
    }
    @for $i from 0 through 10 {
        .mt-sm-#{$i} {
            margin-top: $i*.5rem;
        }
    }
}

@media (min-width: 768px) {
    .fs-md-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
    }
    .fs-md-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
    }
    .fs-md-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
    }
    .fs-md-4 {
        font-size: 1.25rem !important;
    }
    .fs-md-5 {
        font-size: 1.15rem !important;
    }
    .fs-md-6 {
        font-size: 1.075rem !important;
    }
    .fs-md-7 {
        font-size: 0.95rem !important;
    }
    .fs-md-8 {
        font-size: 0.85rem !important;
    }
    .fs-md-9 {
        font-size: 0.75rem !important;
    }
    .fs-md-10 {
        font-size: 0.5rem !important;
    }
    .fs-md-36px {
        font-size: 36px !important;
    }
    .fs-md-60px {
        font-size: 60px !important;
    }
    .w-md-200px {
        width: 200px !important;
    }
    .h-md-200px {
        height: 200px !important;
    }
    .h-md-645px {
        height: 645px !important;
    }
    .w-md-225px {
        width: 225px !important;
    }
    .h-md-225px {
        height: 225px !important;
    }
    .w-md-25 {
        width: 25% !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .w-md-100 {
        width: 100% !important;
    }
    .h-md-550px {
        height: 550px !important;
    }

    .start-md-7 {
        left: 7% !important;
    }
    @for $i from 0 through 10 {
        .mt-md-#{$i} {
            margin-top: $i*.5rem;
        }
    }
}

@media (min-width: 992px) {
    .fs-lg-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
    }
    .fs-lg-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
    }
    .fs-lg-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
    }
    .fs-lg-4 {
        font-size: 1.25rem !important;
    }
    .fs-lg-5 {
        font-size: 1.15rem !important;
    }
    .fs-lg-6 {
        font-size: 1.075rem !important;
    }
    .fs-lg-7 {
        font-size: 0.95rem !important;
    }
    .fs-lg-8 {
        font-size: 0.85rem !important;
    }
    .fs-lg-9 {
        font-size: 0.75rem !important;
    }
    .fs-lg-10 {
        font-size: 0.5rem !important;
    }
    .w-lg-225px {
        width: 225px !important;
    }
    .h-lg-225px {
        height: 225px !important;
    }
    .w-lg-25 {
        width: 25% !important;
    }
    .w-lg-40 {
        width: 40% !important;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
    .pe-lg-7 {
        padding-right: 3.5rem;

    }
    .h-lg-600px {
        height: 600px !important;
    }

    @for $i from 0 through 10 {
        .mt-lg-#{$i} {
            margin-top: $i*.5rem;
        }
        .px-lg-10 {
            padding-top: 5rem !important;
            padding-bottom: 5rem !important;
        }
        .px-lg-10 {
            padding-left: 5rem !important;
            padding-right: 5rem !important;
        }
        .py-lg-16 {
            padding-top: 8rem !important;
            padding-bottom: 8rem !important;
        }

        .transform-to-bottom-lg-95 {
            transform: translateY(-95px) !important;
        }
        .bg-lg-shape {
            background-image: url('section_bg_shape_1.svg') !important;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center
        }
    }
}

@media (min-width: 1200px) {
    .fs-xl-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
    }
    .fs-xl-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
    }
    .fs-xl-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
    }
    .fs-xl-4 {
        font-size: 1.25rem !important;
    }
    .fs-xl-5 {
        font-size: 1.15rem !important;
    }
    .fs-xl-6 {
        font-size: 1.075rem !important;
    }
    .fs-xl-7 {
        font-size: 0.95rem !important;
    }
    .fs-xl-8 {
        font-size: 0.85rem !important;
    }
    .fs-xl-9 {
        font-size: 0.75rem !important;
    }
    .fs-xl-10 {
        font-size: 0.5rem !important;
    }

    .w-xl-25 {
        width: 25% !important;
    }
    .w-xl-40 {
        width: 40% !important;
    }
    .w-xl-50 {
        width: 50% !important;
    }
    .w-xl-75 {
        width: 75% !important;
    }
    .w-xl-100 {
        width: 100% !important;
    }
    .h-xl-750px {
        height: 750px !important;
    }

    @for $i from 0 through 10 {
        .mt-xl-#{$i} {
            margin-top: $i*.5rem;
        }
    }
}

@media (min-width: 1400px) {
    .fs-xxl-6hx {
        font-size: calc(4rem + .8vw) !important;
    }
    .fs-xxl-1 {
        font-size: calc(1.3rem + 0.6vw) !important;
    }
    .fs-xxl-2 {
        font-size: calc(1.275rem + 0.3vw) !important;
    }
    .fs-xxl-3 {
        font-size: calc(1.26rem + 0.12vw) !important;
    }
    .fs-xxl-4 {
        font-size: 1.25rem !important;
    }
    .fs-xxl-5 {
        font-size: 1.15rem !important;
    }
    .fs-xxl-6 {
        font-size: 1.075rem !important;
    }
    .fs-xxl-7 {
        font-size: 0.95rem !important;
    }
    .fs-xxl-8 {
        font-size: 0.85rem !important;
    }
    .fs-xxl-9 {
        font-size: 0.75rem !important;
    }
    .fs-xxl-10 {
        font-size: 0.5rem !important;
    }
    .fs-xxl-100px {
        font-size: 100px !important;
    }
    .w-xxl-25 {
        width: 25% !important;
    }
    .w-xxl-40 {
        width: 40% !important;
    }
    .w-xxl-50 {
        width: 50% !important;
    }
    .w-xxl-75 {
        width: 75% !important;
    }
    .w-xxl-100 {
        width: 100% !important;
    }
    .h-xxl-600px {
        height: 600px !important;
    }

}

.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: black !important;
}
