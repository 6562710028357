#planAccordion {
    .hide-data {
        .accordion-items-image-box, .tt, .target {
            display: none !important;
        }
    }

    .done-circle-mark {
        width: 15px;
        height: 15px;
    }
}


#exercisesContainer {
    .show-target {
        display: none !important;
    }
    .arrow-image {
        transition: all 0.3s ease;
        transform: rotate(180deg);
    }
    .handle-visibility {
        .arrow-image {
            transition: all 0.3s ease;
            transform: rotate(0deg);
        }

        .hide-target {
            display: none !important;
        }

        .show-target {
            display: block !important;
        }
    }

}

.animation-arrow-path{

    @keyframes slide {
        0% {
            top: 0;
        }
        100% {
            top: 100%;
        }
    }
    .animation-arrow {
        animation: slide 2.5s linear infinite;
    }
    .border-dashed{
        border: 1px dashed #aaa !important;
    }
    @keyframes animation-shadow {
        0% {
            box-shadow: 0px 0px 0px 0px #ff004f;
        }
        50% {
            box-shadow: 0px 0px 4px 0px #ff004f;
        }
        100% {
            box-shadow: 0px 0px 0px 0px #ff004f;
        }
    }
    .animation-shadow {
        animation: animation-shadow 1s linear infinite;
        z-index: 30;
    }
    .custom-checkBox {
        position: relative;
        z-index: 10;
        opacity: 1 !important;
    }
    .custom-checkBox:checked {
        background-color: $bg-base !important;
        --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }
}
